import(/* webpackMode: "eager" */ "/app/node_modules/next/dist/client/script.js");
;
import(/* webpackMode: "eager" */ "/app/node_modules/next/font/google/target.css?{\"path\":\"src/app/layout.tsx\",\"import\":\"Montserrat\",\"arguments\":[{\"subsets\":[\"latin\"],\"weight\":[\"400\",\"500\",\"600\",\"700\",\"900\"],\"variable\":\"--font-montserrat\",\"display\":\"block\"}],\"variableName\":\"montserrat\"}");
;
import(/* webpackMode: "eager", webpackExports: ["BigBanner"] */ "/app/src/components/banners/big-banner/big-banner.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["PageFooter"] */ "/app/src/components/layout/page-footer/page-footer.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["PageHeader"] */ "/app/src/components/layout/page-header/page-header.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/app/src/context/global-context-provider.tsx");
;
import(/* webpackMode: "eager" */ "/app/src/scss/colors.scss");
;
import(/* webpackMode: "eager" */ "/app/src/scss/common/fonts.scss");
;
import(/* webpackMode: "eager" */ "/app/src/scss/reset.scss");
;
import(/* webpackMode: "eager" */ "/app/src/scss/global-elements.scss");
;
import(/* webpackMode: "eager" */ "/app/src/scss/swiper-slider.scss");
;
import(/* webpackMode: "eager" */ "/app/src/scss/layout/header.scss");
;
import(/* webpackMode: "eager" */ "/app/src/scss/layout/footer.scss");
;
import(/* webpackMode: "eager" */ "/app/src/scss/layout/page-header.scss");
;
import(/* webpackMode: "eager" */ "/app/src/scss/navbar.scss");
;
import(/* webpackMode: "eager" */ "/app/src/scss/background.scss");
;
import(/* webpackMode: "eager" */ "/app/src/scss/app-page.scss");
;
import(/* webpackMode: "eager" */ "/app/src/scss/app-header.scss");
;
import(/* webpackMode: "eager" */ "/app/src/scss/download-modal.scss");
;
import(/* webpackMode: "eager" */ "/app/src/scss/stats.scss");
;
import(/* webpackMode: "eager" */ "/app/src/scss/share-modal.scss");
;
import(/* webpackMode: "eager" */ "/app/src/scss/inventory.scss");
;
import(/* webpackMode: "eager" */ "/app/src/scss/about-content.scss");
;
import(/* webpackMode: "eager" */ "/app/src/scss/image-slider.scss");
;
import(/* webpackMode: "eager" */ "/app/src/scss/full-screen-image-slider-modal.scss");
;
import(/* webpackMode: "eager" */ "/app/src/scss/reviews.scss");
;
import(/* webpackMode: "eager" */ "/app/src/scss/reviews-modal.scss");
;
import(/* webpackMode: "eager" */ "/app/src/scss/reviews-slider.scss");
;
import(/* webpackMode: "eager" */ "/app/src/scss/ratings-stats.scss");
;
import(/* webpackMode: "eager" */ "/app/src/scss/star-rating.scss");
;
import(/* webpackMode: "eager" */ "/app/src/scss/progress-ring.scss");
;
import(/* webpackMode: "eager" */ "/app/src/scss/technical-details.scss");
;
import(/* webpackMode: "eager" */ "/app/src/scss/remind-me-mobile.scss");
;
import(/* webpackMode: "eager" */ "/app/src/scss/browse-more-apps.scss");
;
import(/* webpackMode: "eager" */ "/app/src/scss/pairing.scss");
;
import(/* webpackMode: "eager" */ "/app/src/scss/tags.scss");
;
import(/* webpackMode: "eager" */ "/app/src/scss/creator-social.scss");
;
import(/* webpackMode: "eager" */ "/app/src/scss/creator.scss");
;
import(/* webpackMode: "eager" */ "/app/src/scss/supported-games-modal.scss");
;
import(/* webpackMode: "eager" */ "/app/src/scss/common/search-input.scss");
;
import(/* webpackMode: "eager" */ "/app/src/scss/desktop-notice.scss");
;
import(/* webpackMode: "eager" */ "/app/src/scss/banners-list.scss");
;
import(/* webpackMode: "eager" */ "/app/src/scss/apps-list.scss");
;
import(/* webpackMode: "eager" */ "/app/src/scss/search-page.scss");
;
import(/* webpackMode: "eager" */ "/app/src/scss/search.scss");
;
import(/* webpackMode: "eager" */ "/app/src/scss/carousel-slider.scss");
;
import(/* webpackMode: "eager" */ "/app/src/scss/common/big-banner.scss");
;
import(/* webpackMode: "eager" */ "/app/src/scss/common/error-placeholder.scss");
;
import(/* webpackMode: "eager" */ "/app/src/scss/breadcrumb.scss");
;
import(/* webpackMode: "eager" */ "/app/src/scss/download-section.scss");
