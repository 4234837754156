import { FC } from 'react';
import SpriteIcon from '../../../shared/sprite-icon';
import { OwAppTile } from '../../../../interfaces/domain/ow-app-tile.modal';
import { AutoCompleteText } from './search-auto-complete-text';

// -----------------------------------------------------------------------------
interface Props {
  index: number;
  item: OwAppTile;
  searchTerm: string
}

// -----------------------------------------------------------------------------
export const SearchAutoCompleteItem: FC<Props> = ({
  index,
  item,
  searchTerm,
}) => {

  const startIndex = item.title.toLowerCase().indexOf(searchTerm);
  const endIndex = startIndex === -1 ? 0 : startIndex + searchTerm.length;

  const handleKeyDown = (e: React.KeyboardEvent<HTMLAnchorElement>): void => {
    const item = e.target as HTMLAnchorElement,
      search = item.closest('div').previousElementSibling.querySelector('input'),
      upListItemSibling = item.parentElement.previousElementSibling,
      downListItemSibling = item.parentElement.nextElementSibling;

    if (e.key === 'ArrowUp') {
      if (upListItemSibling instanceof HTMLElement) {
        upListItemSibling.querySelector('a').focus();
      } else if (search instanceof HTMLInputElement) {
        search.focus();
      }
    } else if (e.key === 'ArrowDown') {
      if (downListItemSibling instanceof HTMLElement) {
        downListItemSibling.querySelector('a').focus();
      }
      e.preventDefault();
    }
  };

  return (
    <li key={index}>
      <a
        href={`/app/${item.seoName} `}
        onKeyDown={handleKeyDown}
      >
        <SpriteIcon icon='search' />
        <AutoCompleteText
          name={item.title}
          startIndex={startIndex}
          endIndex={endIndex}
        />
      </a>
    </li>
  );
};
